@import '~antd/dist/antd.css';
@import '@ant-design/pro-form/dist/form.css';
@import '@ant-design/pro-table/dist/table.css';
@import '@ant-design/pro-card/dist/card.css';
@import '@ant-design/pro-layout/dist/layout.css';
@import '~antd/dist/antd.variable.min.css';

@import '@/assets/css/reset.css';

#root{
    height: 100%;
}