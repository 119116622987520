body{
  background-color: #f6f6f6;  
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-shrink{
  flex-shrink: 0;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.bt {
  border-radius: 4px;
  cursor: pointer;
}
.bt+.bt {
  margin-left: 10px;
}

/* 滚动条隐藏 */
.hideScroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.hideScroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/* 自定义滚动条 */
.customScroll::-webkit-scrollbar {
  width: 6px;
}
.customScroll::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-radius: 3px;
  /*box-shadow:inset 0 0 6px rgba(0,0,0,0.3);*/
}
/* 滚动条滑块 */
.customScroll::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.customScroll::-webkit-scrollbar-thumb:window-inactive {
  background-color: #ccc;
}

/* 高德地图 */
.amap-logo,
.amap-copyright {
  display: none;
  visibility: hidden;
  opacity: 0;
}

/* 文本超出隐藏省略 */
.textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}